import React from 'react'
import { Link } from 'gatsby'
import { FlexibleLink } from '@c/common/FlexibleLink'

import RedLine from '@c/red-line'
import Icon from '@c/icon'
import makeSlug from '@u/make-slug'
import ProjectImage from '@/components/project-image'

const Resource = ({
  locale,
  cards,
  pageSettings,
  contentCounts,
  header,
  digitalzone,
  agileApproach
}) => {
  const { resourcesSlug } = pageSettings
  return (
    <section className="Resource mt-160 mt-lg-200">
      <div className="container">
        <header>
          <h5 className="ff-body fw-text-normal lh-head-flat mb-4">
            {header.titleSmall}
          </h5>
          <h2>{header.titleBig}</h2>
          <RedLine className="my-24" />
          <p>
            <span
              style={{ display: 'inline' }}
              dangerouslySetInnerHTML={{
                __html: `${contentCounts} ${header.contentCountsSuffix}`
              }}
            />{' '}
            <Link to={makeSlug.generic(locale, resourcesSlug)} className="link">
              <b>{header.contentCountsResourcesLinkText}</b>
            </Link>
            .
          </p>
        </header>

        <div className="row mt-40 mt-lg-60">
          {/* BLOG */}
          {cards?.boxes?.length &&
            cards?.boxes.map((card, key) => (
              <div
                key={key}
                className="col-12 col-lg-3 d-flex align-items-stretch mt-40 mt-lg-0"
              >
                <FlexibleLink
                  url={card?.link?.getUrl()}
                  className="Resource-engineer-inner col-12 d-flex flex-column justify-content-between border-box u-shadow"
                  title={
                    <>
                      <div>
                        <h4>{card?.link?.getTitle()}</h4>
                        <div
                          className="fs-small mt-16"
                          dangerouslySetInnerHTML={{ __html: card.description }}
                        />
                      </div>
                      <p className="link-hover-red fs-small">
                        {card.buttonText}
                        <Icon className="ml-8 c-red-dark" name="solidRight" />
                      </p>
                    </>
                  }
                ></FlexibleLink>
              </div>
            ))}
          {/* BOOK */}
          <div className="Resource-book col-12 col-lg-3 mt-40 mt-lg-0">
            <FlexibleLink
              url={cards?.guidebook?.link?.getUrl()}
              className="Resource-book-link d-flex justify-content-center"
              title={
                <ProjectImage
                  className="mt-16"
                  image={cards?.guidebook?.image}
                />
              }
            ></FlexibleLink>
          </div>
          {/* Full Box */}
          <div className="Resource-agenda col-12 mt-40">
            <FlexibleLink
              url={cards?.fullbox?.link?.getUrl()}
              className="Resource-agenda-inner d-flex flex-column flex-lg-row justify-content-between border-box p-0 u-shadow"
              title={
                <>
                  <span className="my-32 ml-32">
                    <h4>
                      {cards?.fullbox?.title}
                      {cards?.fullbox?.title_second && (
                        <>
                          <br />
                          {cards?.fullbox?.title_second}
                        </>
                      )}
                    </h4>
                    <div
                      className="fs-small mt-16"
                      dangerouslySetInnerHTML={{
                        __html: cards?.fullbox?.description
                      }}
                    />
                  </span>
                  <div className="col-12 col-lg-5 mr-100">
                    {cards?.fullbox?.image && (
                      <ProjectImage
                        className="Resource-agenda-image col-12 mx-0 px-0"
                        image={cards?.fullbox?.image}
                      />
                    )}
                  </div>
                </>
              }
            ></FlexibleLink>
          </div>
          {/* DIGITALZONE */}
          <div className="col-12 mt-40 mt-lg-0">
            <div className="row">
              <div className="col-12 mt-40 mt-lg-60 ta-lg-right">
                <p className="h4 fw-head-normal">
                  {digitalzone.linkPrefix}{' '}
                  <Link
                    to={digitalzone.link.getUrl()}
                    className="c-red-dark link-hover fw-head-semibold"
                  >
                    {digitalzone.linkSuffix && (
                      <>
                        {digitalzone.linkSuffix}
                        <Icon className="ml-8 c-red-dark" name="solidRight" />
                      </>
                    )}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Agile approach  */}
        {agileApproach?.titleFirstLine && (
          <div className="row mt-120 mt-lg-100">
            <div className="col-12 col-lg-5 pr-60 mt-32">
              <h4 className="c-dark-60 pr-0 pr-lg-180">
                {agileApproach.titleFirstLine}
              </h4>
              <RedLine className="my-24" />
              <h4 className="c-dark-60">{agileApproach.titleSecondLine}</h4>
              <p className="fw-head-semibold mt-32">
                {agileApproach.firstQuestion}
              </p>
              <p className="c-dark-40 mt-8">{agileApproach.firstAnswer}</p>
              <p className="fw-head-semibold mt-32">
                {agileApproach.secondQuestion}
              </p>
              <p className="c-dark-40 mt-8">{agileApproach.secondAnswer}</p>
            </div>
            <div className="col-12 offset-lg-1 col-lg-5 d-none d-lg-flex">
              {agileApproach?.image && (
                <ProjectImage image={agileApproach?.image} />
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Resource
