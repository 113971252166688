import React from 'react'
import FlexibleLink from '@c/common/FlexibleLink'
import RedLine from '@c/red-line'
import ProjectImage from '@/components/project-image'

const EventEuropa = ({
  logo,
  titleSmall,
  titleBig,
  description,
  image,
  link
}) => (
  <section className="EventEuropa mt-180 mt-lg-200 bg-soft-blue py-32 pt-80">
    <div className="container">
      <div className="EventEuropa-inner">
        <div className="row">
          <div className="col-12 col-lg-5">
            <header>
              {logo && <ProjectImage className="mb-40" image={logo} />}
              <h5 className="ff-body fw-text-normal lh-head-flat mb-4">
                {titleSmall}
              </h5>
              <h2>{titleBig}</h2>
              <RedLine className="my-24 purple-line" />

              <p dangerouslySetInnerHTML={{ __html: description }} />
              <FlexibleLink
                className="btn btn--purple btn--round mt-32"
                url={link?.getUrl()}
                title={link?.getTitle()}
              />
            </header>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-end offset-lg-1 mt-60 mt-lg-0">
            <figure className="EventEuropa-image ta-right">
              {image && <ProjectImage image={image} />}
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default EventEuropa
