import React, { useRef, useEffect } from 'react'
import Slider from '@c/slider-flickity'
import FlexibleLink from '@c/common/FlexibleLink'
import ProjectImage from '@/components/project-image'

const ClientSay = ({ testimonials, link }) => {
  const sliderTextRef = useRef()
  const sliderArrowRef = useRef()

  useEffect(() => {
    if (!sliderArrowRef.current) return
    sliderArrowRef.current.on('select', () => {
      sliderTextRef.current.select(sliderArrowRef.current.selectedIndex)
    })
  }, [sliderArrowRef])

  return (
    <section className="mt-60 mt-lg-40 ta-left">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-7 col-xl-8 py-40 px-0">
            <Slider
              ref={sliderTextRef}
              options={{
                pageDots: false,
                prevNextButtons: false,
                draggable: true,
                accessibility: false
              }}
            >
              {testimonials.map((testimonial, index) => (
                <div
                  key={testimonial.id + index}
                  className="container d-flex flex-wrap px-0"
                >
                  {/* profile */}
                  <div className="col-12 col-lg-5 col-xl-5">
                    <div className="d-flex align-items-center">
                      {testimonial?.profileImage && (
                        <ProjectImage image={testimonial?.profileImage} />
                      )}
                      <div className="ml-16">
                        <h5>{testimonial.name}</h5>
                        <h6 className="fs-x-small fw-head-normal">
                          {testimonial.jobTitle}
                        </h6>
                      </div>
                    </div>
                  </div>
                  {/* what */}
                  <div className="col-12 col-lg-7 col-xl-7 mt-24 mt-lg-0">
                    <p className="fs-small">{testimonial.sentence}</p>
                  </div>
                </div>
              ))}
            </Slider>
            {/* arrow */}
          </div>
          <div className="col-12 col-lg-3 col-xl-2 mb-24 mb-lg-0">
            <Slider
              ref={sliderArrowRef}
              options={{
                prevNextButtons: true
              }}
            >
              {testimonials.map((testimonial) => (
                <i key={testimonial.id}></i>
              ))}
            </Slider>
          </div>
          {/* link */}
          <div className="col-12 col-lg-2 col-xl-1 mt-40 mt-lg-0 ta-xl-right">
            <FlexibleLink
              className="btn btn--red btn--round"
              url={link.getUrl()}
              title={link.getTitle()}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ClientSay
