import React, { useRef, useEffect } from 'react'

import Slider from '@c/slider-flickity'
import PageHeader from '@c/page-header'
import setSliderOptions from '@u/set-slider-options'
import ProjectImage from '@/components/project-image'

const International = ({ titleSmall, titleBig, items }) => {
  const sliderTextRef = useRef()
  const sliderArrowRef = useRef()
  const sliderOptions = setSliderOptions()
  useEffect(() => {
    if (!sliderArrowRef.current) return
    sliderArrowRef.current.on('select', () => {
      sliderTextRef.current.select(sliderArrowRef.current.selectedIndex)
    })
  }, [sliderArrowRef])

  return (
    <section className="International ta-start mt-160">
      <img
        className="International-bg"
        src="/assets/index/international-world.svg"
        alt=""
        width="950"
        height="1226"

      />
      <div className="container">
        <div className="row justify-content-start">
          <div className="col-12 col-lg-6">
            <PageHeader topTitle={titleSmall} title={titleBig} type="h2" />
            <Slider
              className="mt-40 mt-lg-60"
              ref={sliderTextRef}
              options={{
                pageDots: sliderOptions.pageDots,
                prevNextButtons: sliderOptions.prevNextButtons,
                draggable: true,
                accessibility: false
              }}
            >
              {items.map((item, index) => (
                <div key={index} className="slider-item" style={{ minHeight: 'auto' }}>
                  <ProjectImage image={item.image} />
                  <h4 className="mt-16">{item.title}</h4>
                  <div className="h5 my-16 fw-head-normal">
                    {item.eventType}
                  </div>
                  <p className="mt-16">{item.summary}</p>
                </div>
              ))}
            </Slider>
            {sliderOptions.desktopRes && (
              <div className="col-3 mt-40 bg-dark pl-0">
                <Slider
                  className="slider-sub-arrow"
                  options={{
                    draggable: false,
                    prevNextButtons: true
                  }}
                  ref={sliderArrowRef}
                >
                  {items.map((item, i) => (
                    <i key={i}></i>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default International
