import React from 'react'
import { graphql } from 'gatsby'
import DatoCmsEmbeddedModel from '../models/DatoCmsEmbeddedModel'
import Layout from '@c/layout'

import Hero from '@v/index/hero'
import ClientSay from '@v/index/client-say'
import WhatCan from '@v/index/what-can'
import International from '@v/index/international'
import EventEuropa from '@v/index/event-europa'
import Other from '@v/index/other'
import Resource from '@v/index/resource'
import CaseStudies from '@v/index/case-studies'
import DatoCmsLink from '@m/datocms-components/DatoCmsLink'

import makeSlug from '@u/make-slug'

import './index.css'

function addFakeSlugLocales(page) {
  page._allSlugLocales = ['tr', 'en'].map(locale => ({
    locale,
    value: makeSlug.generic(locale)
  }))

  return page
}

const Page = ({ data, pageContext }) => {
  const page = addFakeSlugLocales(data.datoCmsHomepage)
  const [resources = {}] = page.resourcesSection
  const getLinkOrNull = link =>
    link && new DatoCmsLink(Array.isArray(link) ? link[0] : link)
  const resourcesCards = {
    boxes: [
      {
        title: page.resourcesFirstBoxTitle,
        description: page.resourcesFirstBoxDescription,
        buttonText: page.resourcesFirstBoxButtonText,
        link: getLinkOrNull(page.resourcesFirstBoxLink)
      },
      {
        title: page.resourcesSecondBoxTitle,
        description: page.resourcesSecondBoxDescription,
        buttonText: page.resourcesSecondBoxButtonText,
        link: getLinkOrNull(page.resourcesSecondBoxLink)
      },
      {
        title: page.resourcesThirdBoxTitle,
        description: page.resourcesThirdBoxDescription,
        buttonText: page.resourcesThirdBoxButtonText,
        link: getLinkOrNull(page.resourcesThirdBoxLink)
      }
    ],
    guidebook: {
      title: page.resourcesFourthBoxTitle,
      image: page.resourcesFourthBoxImage,
      link: getLinkOrNull(page.resourcesFourthBoxLink)
    },
    fullbox: {
      title: page.resourcesFullBoxTitle,
      description: page.resourcesFullBoxDescription,
      image: page.resourcesFullBoxImage,
      link: getLinkOrNull(page.resourcesFullBoxLink)
    }
  }
  const model = new DatoCmsEmbeddedModel(page, {
    locale: page.locale,
    slugLocales: page._allTitleLocales.map(sl => ({ ...sl, value: '' }))
  })
  const heroGetInTouchLink = new DatoCmsLink(page.heroGetInTouchLink[0] || {})
  const clientSayGetInTouchLink = new DatoCmsLink(
    page.clientSayGetInTouchLink[0] || {}
  )
  const servicesRightLink = new DatoCmsLink(page.servicesRightLink[0] || {})
  const digitalzoneSmallLink = new DatoCmsLink(
    page.digitalzoneSmallLink[0] || {}
  )
  const links = model.generateAlternateLinks()
  const caseStudies = data?.datoCmsHomepage?.caseStudies

  return (
    <Layout
      locale={pageContext.locale}
      links={links}
      seo={{ ...model.getSeoComponentProps() }}
    >
      <Hero
        header={{ topTitle: page.sloganSmall, title: page.sloganBig }}
        phoneNumber={page.phoneNumber}
        presentationText={page.presentationText}
        image={page.heroImage}
        imageMobile={page.heroImageMobile}
        getInTouchButtonText={page.heroGetInTouchButtonText}
        getInTouchLink={heroGetInTouchLink}
      />

      {page.displayClientSay && (
        <ClientSay
          testimonials={page.clientSaySentences}
          link={clientSayGetInTouchLink}
        />
      )}

      <WhatCan
        topTitle={page.servicesSloganSmall}
        title={page.servicesSloganBig}
        rightTitle={page.servicesRightTitle}
        rightLink={servicesRightLink}
        sentence={page.servicesPresentation}
        indexData={data}
        services={page.serviceList}
      />

      <International
        titleSmall={page.weAreInternationalTitleSmall}
        titleBig={page.weAreInternationalTitleBig}
        items={page.weAreInternational}
      />

      <EventEuropa
        logo={page.digitalzoneLogo}
        titleSmall={page.digitalzoneTitleSmall}
        titleBig={page.digitalzoneTitleBig}
        description={page.digitalzoneDescription}
        image={page.digitalzoneImageFirst}
        link={digitalzoneSmallLink}
      />

      <CaseStudies
        topTitle={data?.datoCmsHomepage?.caseStudiesTopTitle}
        title={data?.datoCmsHomepage?.caseStudiesTitle}
        description={data?.datoCmsHomepage?.caseStudiesDescription}
        studies={caseStudies}
        pageSettings={data.pageSettings}
        references={data?.datoCmsHomepage?.references}
      />

      <Other services={page.otherServicesList} />

      <Resource
        locale={pageContext.locale}
        pageSettings={data.pageSettings}
        contentCounts={pageContext.contentCounts}
        cards={resourcesCards}
        header={{
          titleSmall: resources.titleSmall,
          titleBig: resources.titleBig,
          contentCountsSuffix: resources.contentCountsSuffix,
          contentCountsResourcesLinkText:
            resources.contentCountsResourcesLinkText
        }}
        digitalzone={{
          linkPrefix: page.digitalzonePrefix,
          linkSuffix: page.digitalzoneSuffix,
          link: getLinkOrNull(page.digitalzoneLink)
        }}
        agileApproach={{
          titleFirstLine: resources.agileApproachTitleFirstLine,
          titleSecondLine: resources.agileApproachTitleSecondLine,
          firstQuestion: resources.agileApproachFirstQuestion,
          firstAnswer: resources.agileApproachFirstAnswer,
          secondQuestion: resources.agileApproachSecondQuestion,
          secondAnswer: resources.agileApproachSecondAnswer,
          image: resources.agileApproachImage
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query MyQuery($locale: String) {
    datoCmsHomepage(locale: { eq: $locale }) {
      _allTitleLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      id
      locale
      title

      # hero
      sloganSmall
      sloganBig
      presentationText
      phoneNumber
      heroImage {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      heroImageMobile {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      heroGetInTouchButtonText
      heroGetInTouchLink {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }
      digitalzoneSmallLink {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }

      displayClientSay
      clientSaySentences {
        id
        jobTitle
        name
        contactUrl
        sentence
        profileImage {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 50)
              }
            }
          }
        }
      }
      clientSayGetInTouchLink {
        id
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }
      servicesSloganSmall
      servicesSloganBig
      servicesPresentation
      servicesRightTitle
      servicesRightLink {
        id
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }
      serviceList {
        id
        titleFirst
        titleSecond
        description
        icon {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }

        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }

        url
      }

      # we are international
      weAreInternationalTitleSmall
      weAreInternationalTitleBig
      weAreInternational {
        title
        summary
        eventType
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }

      # digitalzone section
      digitalzoneLogo {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      digitalzoneTitleSmall
      digitalzoneTitleBig
      digitalzoneDescription
      digitalzoneImageFirst {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      # other services
      otherServicesList {
        id
        title
        titleFirst
        descriptionFirst
        titleSecond
        descriptionSecond
        displayImageAtRight
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }

      # Resources
      resourcesSection {
        titleBig
        titleSmall
        contentCountsResourcesLinkText
        contentCountsSuffix

        agileApproachTitleFirstLine
        agileApproachTitleSecondLine
        agileApproachFirstQuestion
        agileApproachFirstAnswer
        agileApproachSecondQuestion
        agileApproachSecondAnswer
        agileApproachImage {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
      # Big Digitalzone Link
      digitalzonePrefix
      digitalzoneLink {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }
      digitalzoneSuffix
      # Resources Cards
      resourcesFirstBoxTitle
      resourcesFirstBoxDescription
      resourcesFirstBoxButtonText
      resourcesFirstBoxLink {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }
      resourcesSecondBoxTitle
      resourcesSecondBoxDescription
      resourcesSecondBoxButtonText
      resourcesSecondBoxLink {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }
      resourcesThirdBoxTitle
      resourcesThirdBoxDescription
      resourcesThirdBoxButtonText
      resourcesThirdBoxLink {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }
      resourcesFourthBoxTitle
      resourcesFourthBoxImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      resourcesFourthBoxLink {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }
      resourcesFullBoxTitle
      resourcesFullBoxDescription
      resourcesFullBoxImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      resourcesFullBoxLink {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }

      # References & Case Studies
      caseStudiesTopTitle
      caseStudiesTitle
      caseStudiesDescription
      caseStudies {
        title
        descriptionSmall
        slug
        locale
        imageCard {
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
      references {
        id
        name
        website
        logo {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }

    # Page Details
    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      locale
      ...PageSettingFragment
    }
  }
`

export default Page
