import React, { useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'

import Slider from '@c/slider-flickity'
import PageHeader from '@c/page-header'
import FlexibleLink from '@c/common/FlexibleLink'
import setSliderOptions from '@u/set-slider-options'
import ProjectImage from '@/components/project-image'

const WhatCan = ({
  topTitle,
  title,
  rightTitle,
  rightLink,
  sentence,
  services
}) => {
  const sliderOptions = setSliderOptions()
  const sliderArrowRef = useRef()
  const sliderTextRef = useRef()
  useEffect(() => {
    if (!sliderArrowRef.current) return
    sliderArrowRef.current.on('select', () => {
      sliderTextRef.current.select(sliderArrowRef.current.selectedIndex)
    })
  }, [sliderArrowRef])
  return (
    <section className="what-can mt-40 mb-40">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-12 col-lg-4">
            <PageHeader topTitle={topTitle} title={title} type="h2" />
            <p className="mt-32">{sentence}</p>
          </div>
          <div className="col-12 col-lg-6 offset-lg-2 ta-right d-none d-lg-block">
            <div className="h5">
              <span className="d-block fw-head-normal">{rightTitle}</span>
              <FlexibleLink
                className="d-block fw-head-semibold"
                url={rightLink.getUrl()}
                title={rightLink.getTitle()}
              />
            </div>
          </div>
        </div>

        <Slider
          className="mt-32 mt-lg-60"
          options={sliderOptions}
          showOutside
          ref={sliderTextRef}
        >
          {services.map((item) => {
            const image = get(item, 'image')
            const icon = get(item, 'icon')

            return (
              <Link
                key={item.id}
                to={item.url}
                className="slider-item border-box border-box--hover"
              >
                <figure className="ta-center">
                  {image && <ProjectImage image={image} />}
                </figure>

                <div className="d-flex align-items-center mt-32">
                  <h3 className="flex-grow-1 fs-x-large">
                    <span className="d-block fw-head-normal">
                      {item.titleFirst}
                    </span>
                    <span className="d-block">{item.titleSecond}</span>
                  </h3>

                  {icon && (
                    <ProjectImage
                      style={{ width: 40, height: 40 }}
                      image={icon}
                    />
                  )}
                </div>

                <p className="fs-small mt-16">{item.description}</p>
              </Link>
            )
          })}
        </Slider>
        {sliderOptions.desktopRes && (
          <Slider
            className="slider-sub-arrow slider-sub-arrow-narrow"
            options={{
              wrapAround: true,
              draggable: false,
              prevNextButtons: true
            }}
            ref={sliderArrowRef}
          >
            {services.map((i, key) => (
              <i key={key}></i>
            ))}
          </Slider>
        )}
      </div>
    </section>
  )
}

export default WhatCan
