function setSliderOptions() {
  let sliderOptions = {
    pageDots: false,
    prevNextButtons: false,
    desktopRes: true
  }
  if (typeof window !== 'undefined' && window.innerWidth <= 992) {
    sliderOptions.pageDots = true
    sliderOptions.prevNextButtons = true
    sliderOptions.desktopRes = false
    sliderOptions.draggable = true
  }
  if (typeof window !== 'undefined' && window.innerWidth <= 400) {
    sliderOptions.prevNextButtons = false
  }
  return sliderOptions
}

export default setSliderOptions
