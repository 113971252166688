import React, { useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import RedLine from '@c/red-line'
import Slider from '@c/slider-flickity'
import setSliderOptions from '@u/set-slider-options'
import makeSlug from '@u/make-slug'
import ProjectImage from '@/components/project-image'

const CaseStudies = ({
  title,
  topTitle,
  description,
  studies,
  pageSettings,
  references
}) => {
  const sliderTextRef = useRef()
  const sliderArrowRef = useRef()
  const sliderOptions = setSliderOptions()

  useEffect(() => {
    if (!sliderArrowRef.current) return
    sliderArrowRef.current.on('select', () => {
      sliderTextRef.current.select(sliderArrowRef.current.selectedIndex)
    })
  }, [sliderArrowRef])

  return (
    <div className="CaseStudies container-fluid mt-60 mt-lg-120">
      {/* title */}
      <div className="row">
        <div className="col-12 col-lg-7 offset-lg-1">
          <h5 className="ff-body fw-text-normal lh-head-flat mb-4">
            {topTitle}
          </h5>
          <h2>{title}</h2>
          <RedLine className="my-24" />
        </div>
        <div className="col-12 col-lg-10 offset-lg-1 d-flex justify-content-between">
          <p>{description}</p>
          {sliderOptions.desktopRes && (
            <div className="col-2">
              <Slider
                className="slider-sub-arrow"
                options={{
                  wrapAround: true,
                  draggable: false,
                  prevNextButtons: true
                }}
                ref={sliderArrowRef}
              >
                {studies.map((i, key) => (
                  <i key={key}></i>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </div>

      {/* studies */}
      <Slider
        className="mt-32 mt-lg-60"
        ref={sliderTextRef}
        options={{
          wrapAround: true,
          draggable: true,
          pageDots: sliderOptions.pageDots,
          prevNextButtons: sliderOptions.prevNextButtons
        }}
      >
        {studies.map((item, key) => (
          <Link
            key={key}
            to={makeSlug.generic(
              item?.locale,
              pageSettings?.caseStudiesSlug,
              item?.slug
            )}
            className="CaseStudies-item col-12 col-lg-3 u-shadow mx-32"
          >
            <div className="row flex-column align-items-center border-all">
              <div className="ta-center mt-40 mb-24">
                <h4 className="c-dark">{item.title}</h4>
                <p className="c-dark-60 mt-8 pt-4 fs-small">
                  {item.descriptionSmall}
                </p>
              </div>
              <div className="col-12 p-0 m-0">
                <ProjectImage
                  width="100"
                  height="120"
                  style={{ width: '100%', height: '100%' }}
                  image={item?.imageCard}
                />
              </div>
            </div>
          </Link>
        ))}
      </Slider>

      {/* references */}
      <div className="col-12 col-lg-10 offset-lg-1">
        <div className="mt-80 ta-center">
          <RedLine className="my-24" />
        </div>
        <Slider
          className="mt-60 px-60"
          options={{
            pageDots: false,
            prevNextButtons: true,
            draggable: true
          }}
        >
          {references.map((item, index) => (
            <div key={index} className="col-12 col-lg-3">
              <a
                href={item.website}
                title={item.name}
                target="_blank"
                rel="noopener nofollow noreferrer"
              >
                <div className="pl-0 pl-lg-16 ta-center gray-scale back-from-gray-scale">
                  <ProjectImage image={item?.logo} />
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default CaseStudies
