import React from 'react'

import RedLine from '@c/red-line'
import ProjectImage from '@/components/project-image'

const Other = ({ services }) => (
  <section className="Other mt-160 mt-lg-200">
    <div className="container">
      {services.map(
        (
          {
            id,
            title,
            titleFirst,
            descriptionFirst,
            titleSecond,
            descriptionSecond,
            image,
            displayImageAtRight
          },
          i
        ) => (
          <div
            key={id}
            className={
              'row align-items-center ' +
              (i > 0 ? 'mt-80 ' : '') +
              (displayImageAtRight ? '' : 'mt-80 mt-lg-160')
            }
          >
            <div
              className={
                'col-12 col-lg-6 ' +
                (displayImageAtRight ? '' : 'offset-lg-2 order-lg-1')
              }
            >
              <figure className="ta-center ta-lg-right">
                {image && <ProjectImage image={image} />}
              </figure>
            </div>
            <div
              className={
                'col-12 col-lg-4 mt-40 mt-lg-0 ' +
                (displayImageAtRight ? 'offset-lg-2' : '')
              }
            >
              <header>
                <h3>{title}</h3>
                <RedLine className="my-24" />
                {/* - */}
                <h4>{titleFirst}</h4>
                <p className="mt-16">{descriptionFirst}</p>
                {/* - */}
                <h4 className="mt-40">{titleSecond}</h4>
                <p className="mt-16">{descriptionSecond}</p>
              </header>
            </div>
          </div>
        )
      )}
    </div>
  </section>
)

export default Other
