import React from 'react'

import PageHeader from '@c/page-header'
import { Link } from 'gatsby'
import ProjectImage from '@/components/project-image'

const Hero = ({
  header: { topTitle, title },
  phoneNumber,
  presentationText,
  image,
  imageMobile,
  getInTouchButtonText,
  getInTouchLink
}) => (
  <section className="Hero ta-center">
    {/* phone number */}
    <div className="container phone-number d-none d-lg-flex align-items-start pb-0 pb-lg-60">
      <p className="fw-head-bold fs-large">{phoneNumber}</p>
    </div>

    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-8 offset-lg-2">
          <PageHeader topTitle={topTitle} title={title} />
          <p className="mt-16">{presentationText}</p>
          <Link to={getInTouchLink?.getUrl()}>
            <p className="btn btn--red btn--round mt-16">
              {getInTouchButtonText}
            </p>
          </Link>
        </div>
      </div>
    </div>
    <figure className="mt-32 mt-lg-0">
      {image && <ProjectImage className="d-none d-lg-inline" image={image} />}
      {imageMobile && (
        <ProjectImage className="d-lg-none" image={imageMobile} />
      )}
    </figure>
  </section>
)

export default Hero
